import React from "react"


const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <h2>404</h2>
    </main>
  )
}

export default NotFoundPage
